export default {
    zh:{
        hdgs:{
            queryParam:{
                gsmc:'公司名称',
                gsmc_placeholder:'请输入公司名称',
                lxr:'联系人',
                lxr_placeholder:'请输入联系人',
            },
            columns:{
                F_GSMC:'公司名称',
                F_LXR:'联系人',
                F_LXYX:'联系邮箱',
                F_LXDZ:'联系地址',
            },
            form:{
                gsmc:'公司名称',
                gsmc_placeholder:'请输入公司名称',
                gsmc_rule: '请输入公司名称',
                lxr:'联系人',
                lxr_placeholder:'请输入联系人',
                lxr_rule: '请输入联系人',
                lxyx:'联系邮箱',
                lxyx_placeholder:'请输入联系邮箱',
                lxdz:'联系地址',
                lxdz_placeholder:'请输入联系地址',
            },
        }
    },
    en:{
        hdgs:{
            queryParam:{
                gsmc:'公司名称',
                gsmc_placeholder:'请输入公司名称',
                lxr:'联系人',
                lxr_placeholder:'请输入联系人',
            },
            columns:{
                F_GSMC:'公司名称',
                F_LXR:'联系人',
                F_LXYX:'联系邮箱',
                F_LXDZ:'联系地址',
            },
            form:{
                gsmc:'公司名称',
                gsmc_placeholder:'请输入公司名称',
                gsmc_rule: '请输入公司名称',
                lxr:'联系人',
                lxr_placeholder:'请输入联系人',
                lxr_rule: '请输入联系人',
                lxyx:'联系邮箱',
                lxyx_placeholder:'请输入联系邮箱',
                lxdz:'联系地址',
                lxdz_placeholder:'请输入联系地址',
            },
        }
    }
}